import 'swiper/components/pagination'
import 'swiper/swiper.scss'
import * as GA from '../../components/GA'
import App from '../../components/App'
import Dice from '../../components/Dice'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import HelpFind from '../../components/HelpFind'
import React, { useCallback, useRef } from 'react'
import SEO, { getSeoMedia } from '../../atoms/SEO'
import SpeakersCard from '../../components/SpeakersCard'
import Swiper from 'react-id-swiper'
import SwiperCore, { Pagination } from 'swiper'
import cn from 'classnames'
import { ArticleJsonLd } from 'gatsby-plugin-next-seo'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { MDXProvider } from '../../molecules/MDXProvider'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { graphql } from 'gatsby'
import { selectDeviceSize } from '../../state/reducers/deviceSlice'
import { useLocation } from '@reach/router'
import { useSelector } from 'react-redux'
// eslint-disable-next-line sort-imports-es6-autofix/sort-imports-es6
import '../../styles/Webinar.scss'
import { BreadCrumbs, BreadcrumbSEO } from '../../molecules/BreadCrumbs'
import { LookingTooInStaticQueryWebinars } from '../../components/LookingToo/LookingTooInStaticQuery/webinars'

SwiperCore.use([Pagination])

const Webinar = ({ data }) => {
  const wWidth = useSelector(selectDeviceSize)
  const paginationRef = useRef(null)
  const { pathname } = useLocation()

  const {
    title,
    created_at: createdAt,
    age_limit: ageLimit,
    link_youtube: linkYoutube,
    participant,
    moder,
    description,
    img
  } = data.mdx.frontmatter

  const reg =
    /^(https?:\/\/)?((www\.)?(youtube(-nocookie)?|youtube.googleapis)\.com.*(v\/|v=|vi=|vi\/|e\/|embed\/|user\/.*\/u\/\d+\/)|youtu\.be\/)([_0-9a-z-]+)/i

  const params = {
    loop: false,
    grabCursor: true,
    pagination: {
      clickable: true,
      type: 'bullets',
      el: paginationRef.current
    },
    slidesPerView: 1,
    spaceBetween: 0,
    preventClicks: false,
    preventClicksPropagation: false,
    on: {
      beforeInit: ({ params }) => {
        params.pagination.el = paginationRef.current
      }
    }
  }

  const handleClickSeeAlso = useCallback(() => {
    GA.Event.SeeAlso('OpenWebinar')
  }, [])

  return (
    <>
      <App>
        <SEO
          children={
            <>
              <ArticleJsonLd
                authorName={participant.map(({ fio }) => fio)}
                datePublished={new Date(createdAt).toISOString()}
                description={description}
                headline={title}
                images={participant.map(({ img, fio }) => ({
                  pathname: getImage(img).images.fallback.src,
                  alt: fio
                }))}
                overrides={{
                  '@type': 'CreativeWork'
                }}
                url={`${process.env.GATSBY_SITEURL}${pathname}`}
              />
              <BreadcrumbSEO pageName={title} />
            </>
          }
          description={description}
          images={getSeoMedia([
            { pathname: getImage(img).images.fallback.src, alt: 'webinar' }
          ])}
          title={`Вебинар ${title} | YouTalk`}
          type="Video.movie"
          video={{
            releaseDate: new Date(createdAt).toISOString(),
            actors: []
          }}
          videos={getSeoMedia([
            {
              url: linkYoutube,
              alt: 'webinar'
            }
          ])}
        />
        <Header />
        <BreadCrumbs pageName={title} />
        <main className="main">
          <section className={moder.fio ? 'material' : 'material-no_moder'}>
            <div className="container">
              <div className="row">
                <div className="designer-col col-12 offset-md-1 col-md-10 offset-lg-2 col-lg-8">
                  <div className="material__wrap">
                    <span className="material__date">{createdAt}</span>
                  </div>
                  <h1 className="material__title">{title}</h1>
                  {ageLimit > 17 && <Dice />}
                  {(linkYoutube && linkYoutube.length) > 0 &&
                    linkYoutube.match(reg) !== null && (
                      <div className="material__video-img-wrap">
                        <iframe
                          allowFullScreen
                          loop
                          muted
                          playsInline
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          frameBorder="0"
                          src={`https://www.youtube.com/embed/${
                            linkYoutube.match(reg)[7]
                          }?&amp;modestbranding=1&amp;iv_load_policy=3&amp;rel=0;enablejsapi=1&mute=1`}
                          title="title"
                        ></iframe>
                      </div>
                    )}
                  <div className="material__desc b-content">
                    <MDXProvider>
                      <MDXRenderer>{data.mdx.body}</MDXRenderer>
                    </MDXProvider>
                  </div>
                </div>
              </div>
              {moder && moder.fio !== '' && moder.fio !== null && (
                <div className="row">
                  <div className="designer-col col-12 offset-sm-1 col-sm-10 offset-md-2 col-md-8 offset-lg-3 col-lg-6">
                    <h2 className="material__subtitle">Модератор дискуссии</h2>
                    <div className="material__moder">
                      <div key={moder.id} className="moderators-card">
                        <GatsbyImage
                          alt={moder.fio}
                          className="moderators-card__img"
                          image={getImage(moder.img)}
                        />
                        <h5 className="moderators-card__name h5">
                          {moder.fio}
                        </h5>
                        <p className="moderators-card__post">
                          {moder.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>

          {participant && participant.length > 0 && (
            <section
              className={cn('speakers', {
                speakers_bg: participant.length > 1,
                speakers_one: participant.length === 1 && !moder.fio
              })}
            >
              <div className="container">
                <div className="row">
                  <div className="designer-col col-12">
                    <h2
                      className={cn('speakers__title', {
                        speakers__title_one: participant.length === 1
                      })}
                    >
                      {participant && participant.length > 1
                        ? 'Спикеры'
                        : 'Спикер'}
                    </h2>
                    <div className="speakers__cards speakers-cards">
                      <div
                        className={cn('row', {
                          row_center: participant.length === 1
                        })}
                      >
                        <>
                          {wWidth > 767 ? (
                            <>
                              {participant.map((el) => (
                                <div
                                  key={el.id}
                                  className="col-sm-4 col-md-4 col-lg-3"
                                >
                                  <SpeakersCard
                                    className="speakers-cards__card"
                                    {...el}
                                  />
                                </div>
                              ))}
                            </>
                          ) : (
                            <div className="designer-col col-12">
                              {participant.length > 1 ? (
                                <>
                                  <Swiper
                                    {...params}
                                    containerClass="speakers-cards__slider"
                                  >
                                    {participant.map((el) => (
                                      <SpeakersCard key={el.id} {...el} />
                                    ))}
                                  </Swiper>
                                  <div
                                    ref={paginationRef}
                                    className="speakers-cards__dots sl-dots"
                                  ></div>
                                </>
                              ) : (
                                <SpeakersCard
                                  className="speakers-cards__card"
                                  {...participant[0]}
                                />
                              )}
                            </div>
                          )}
                        </>
                      </div>
                    </div>
                  </div>
                  {participant.length === 1 && (
                    <div className="speakers__bordered" />
                  )}
                </div>
              </div>

              {participant.length > 1 && (
                <>
                  <img
                    alt=""
                    className="speakers__top-decor"
                    src="/img/icons/webinar/top-decor.svg"
                  />
                  <img
                    alt=""
                    className="speakers__bot-decor"
                    src="/img/icons/webinar/bot-decor.svg"
                  />
                </>
              )}
            </section>
          )}

          <HelpFind onClick={GA.useNewOpenForm('webinar')} />
          <LookingTooInStaticQueryWebinars
            handleClick={handleClickSeeAlso}
            id={data.mdx.id}
            type="webinars"
          />
        </main>

        <Footer />
      </App>
    </>
  )
}

export default Webinar

export const query = graphql`
  query Webinar($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        participant {
          description
          fio
          id
          img {
            childImageSharp {
              gatsbyImageData
            }
          }
          order
        }
        moder {
          id
          fio
          img {
            childImageSharp {
              gatsbyImageData
            }
          }
          description
        }
        age_limit
        created_at
        description
        link_youtube
        title
        img {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      body
      id
    }
  }
`
