import './index.scss'
import React from 'react'

const Dice = () => (
  <div className="material__dice">
    <span className="material__dice-content">
      Этот материал предназначен для людей старше 18 лет
    </span>
  </div>
)

export default Dice
