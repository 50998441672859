import './index.scss'
import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const SpeakersCard = ({ img, fio, description, className = '' }) => (
  <div className={`${className} speakers-card`}>
    <GatsbyImage
      alt={fio}
      className="speakers-card__img"
      image={getImage(img)}
    />
    <h5 className="speakers-card__name h5">{fio}</h5>
    <p className="speakers-card__desc">{description}</p>
  </div>
)
export default SpeakersCard
