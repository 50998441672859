import LookingToo from '..'
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

const queryLookingTooWebinars = graphql`
  query LookingTooWebinars {
    webinars: allMdx(filter: { fileAbsolutePath: { regex: "/webinars/" } }) {
      nodes {
        frontmatter {
          age_limit
          created_at
          description
          link_youtube
          title
          img {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        slug
        id
      }
      totalCount
    }
  }
`

export const LookingTooInStaticQueryWebinars = (props) => (
  <StaticQuery
    query={queryLookingTooWebinars}
    render={(data) => <LookingToo data={data.webinars} {...props} />}
  />
)
